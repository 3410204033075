module controllers {
    export module master {
        interface ISupplierGradingUpdateScope extends ng.IScope {
        }

        interface ISupplierGradingUpdateScopeParams extends ng.ui.IStateParamsService {
            comId: number;
        }

        export class supplierGradingUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'documentRepositoryService',
                'supplierGradingService',
                'transportModeService',
            ];


            comId: number;
            supplierGradingMaster: interfaces.master.ISupplierGradingMaster;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;

            yesNoItems = [{
                id: null,
                label: 'Please Select',
            }, {
                id: true,
                label: 'Yes',
            }, {
                id: false,
                label: 'No',
            }];

            constructor(
                private $scope: ISupplierGradingUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ISupplierGradingUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public supplierGradingService: services.master.supplierGradingService,
                public transportModeService: services.applicationcore.transportModeService,
            ) {

                this.comId = $stateParams.comId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadSupplierGrading(this.comId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadSupplierGrading(Id: number) {
                return this.supplierGradingService.getSupplierGrading(Id).query((data: interfaces.master.ISupplierGradingMaster) => {

                    this.supplierGradingMaster = data;
                    this.breadCrumbDesc = this.supplierGradingMaster.Description;

                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadTransportModes() {
                return this.transportModeService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            SaveSupplierGrading(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.supplierGradingService.save().save(this.supplierGradingMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.comId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveSupplierGrading().then((data: boolean) => {
                            if (data) {
                                this.loadSupplierGrading(this.comId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.SupplierGrading.Update", { comId: this.comId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveSupplierGrading().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }
        };

        angular.module("app").controller("supplierGradingUpdateCtrl", controllers.master.supplierGradingUpdateCtrl);
    }
}
